'use strict';

angular.module('enervexSalesappApp').service('DiscussionsService', function(Discussion, DiscussionComment, Attachment, $stateParams) {
	this.getDiscussions = getDiscussions;
	this.getDiscussion = getDiscussion;
	this.addDiscussion = addDiscussion;
	this.updateDiscussion = updateDiscussion;
	this.removeDiscussion = removeDiscussion;
	this.getComments = getComments;
	this.saveComment = saveComment;
	this.updateComment = updateComment;
	this.removeComment = removeComment;
	this.addAttachment = addAttachment;

	function getDiscussions() {
		return Discussion.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		});
	}

	function getDiscussion() {
		return Discussion.get({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			id: $stateParams.discussionId
		});
	}

	function addDiscussion(discussion) {
		return Discussion.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, discussion);
	}

	function updateDiscussion(discussion) {
		return Discussion.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: discussion._id
		}, discussion);
	}

	function getComments() {
		return DiscussionComment.query({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: $stateParams.discussionId,
		});
	}

	function removeDiscussion(discussion) {
		return Discussion.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: discussion._id
		}, discussion);
	}

	// function addComment(discussion, comment, res) {
	// 	return DiscussionComment.save({
	// 		accountId: $stateParams.accountId,
	// 		jobId: $stateParams.jobId,
	// 		discussionId: res._id
	// 	}, comment)
	// }

	function saveComment(newComment, _params) {
		var params = _params ? _params : {
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: $stateParams.discussionId
		}
		return DiscussionComment.save(params,newComment);
	}

	function updateComment(comment) {
		return DiscussionComment.update({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: $stateParams.discussionId,
			commentId: comment._id
		}, comment);
	}

	function removeComment(comment) {
		return DiscussionComment.remove({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId,
			discussionId: $stateParams.discussionId,
			commentId: comment._id
		}, comment);
	}

	function addAttachment(fd) {
		return Attachment.save({
			accountId: $stateParams.accountId,
			jobId: $stateParams.jobId
		}, fd);
	}
});
